import React from "react";
import Layout from "../../components/Layout";
import SectionContent from "../../components/Sections/SectionContent";
import Row from "../../components/Lotteries/Row";
import { Link } from "gatsby";

const Lottery = ({
    pageContext: {
        lotteries,
        lotteryName,
        isDisabledMetaIndex = false
    }
}) => (
    <Layout isDisabledMetaIndex={isDisabledMetaIndex}>
        <SectionContent
            title={lotteryName}
            titleSize={'bigger'}
            customClass='return_btn-wrapper'
        >
            <Link to={'/'} className='return_btn' alt='Return to homepage' />
            <div className="lotteries-results__container">
                {
                    lotteries && lotteries.map(lotteryDraw => (
                        <Row key={`${lotteryDraw.id}-${lotteryDraw.draw_id}`} lottery={lotteryDraw} />
                    ))
                }
            </div>
        </SectionContent>
        <SectionContent title='We have over 160 draws from around the world.'>
            <p className={'text-center'}>
                Are you interested in historical results or unable to view our website for some reason? Just send us a list of draws that are of interest to you, and we’ll provide you with all the necessary information through WhatsApp.
                <a href="https://wa.me/27661800842/?text=help" className='btn-link'> Send me Results</a>
            </p>
        </SectionContent>
    </Layout>
);

export default Lottery;
